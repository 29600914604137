import React from 'react'
import './hashtags.css'

function Hashtags() {
    return (
        <div className='trendPost-container'>
            <div className='trend-hashtags'>
                <h6>Trending #</h6>
                <div className='trendy'>

                </div>
            </div>
            <div className='user-hashtags'>
                <h6>Your #</h6>
                <div className='userh'>

                </div>
            </div>
        </div>
    )
}

export default Hashtags